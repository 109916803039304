import * as React from 'react';
import { Link } from 'gatsby';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { StaticImage } from 'gatsby-plugin-image';

import '../styles/global.css';

export default function NotFoundPage() {
   return (
      <div className='bg-white min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid place-items-center lg:px-8'>
         <div className='flex justify-center items-center'>
            <StaticImage src='../images/hunter-environmental-logo-blue-fill.svg' alt='Hunter Environmental Blue Logo' />
         </div>
         <div className='max-w-max mx-auto mt-24'>
            <main className='sm:flex md:text-left text-center'>
               <p className='text-4xl font-extrabold text-snow sm:text-5xl'>404</p>
               <div className='sm:ml-6'>
                  <div className='sm:border-l sm:border-gray-200 sm:pl-6'>
                     <h1 className='text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl'>Page not found</h1>
                     <p className='mt-1 text-base text-gray-500'>Please check the URL in the address bar and try again.</p>
                  </div>
                  <div className='mt-10 flex space-x-3 md:pl-0 md:justify-start md:items-start justify-center items-center w-full text-center sm:border-l sm:border-transparent'>
                     <Link
                        to='/'
                        className='inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-snow hover:bg-snow-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-snow'
                     >
                        Go back home
                     </Link>
                     <AnchorLink
                        to='/snow#contact'
                        className='inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-snow-light bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                     >
                        Contact
                     </AnchorLink>
                  </div>
               </div>
            </main>
         </div>
      </div>
   );
}
